<template>
  <div class="wrapper">
    <div class="wrapper__card">
      <div class="wrapper__card__header">
        <div class="left">
          <span>Upload de documentos</span>
        </div>
      </div>

      <div class="wrapper__card__body">
        <div class="content">
          <div class="content__header">
            <span>Faça upload do documento para atualizar a base:</span>
            <br />
          </div>

          <div class="content__body">
            <div class="actions-middle">
              <z-btn
                :isLoading="false"
                text="Escolher arquivo"
                primary
                large
                color="primary"
                style="flex: 0 1 auto"
                @click="openFilePicker()"
              />
              <z-input
                v-model="computedFile.selected_file.name"
                :hideDetails="true"
                :label="documentLabel"
                type="text"
                style="flex: 2 1 150px"
                readonly
              />
              <v-icon @click="resetInfos"> mdi-delete </v-icon>
              <!-- <z-btn
                :isLoading="false"
                :disabled="!hasDocument"
                icon=" $delete_forever_rounded"
                text="Remover arquivo"
                color="red"
                large
                @click="resetInfos"
              /> -->
            </div>

            <v-checkbox
              v-model="deleteData"
              :disabled="!hasDocument"
              hide-details
              label="Limpar valores da tabela antes de importar o csv"
            ></v-checkbox>

            <input
              @change="previewFiles"
              type="file"
              ref="file"
              accept=".csv"
              style="display: none"
            />
          </div>

          <div class="content__footer">
            <z-btn
              :isLoading="false"
              :disabled="!hasDocument"
              icon=" $send"
              text="Enviar"
              primary
              large
              width="100%"
              color="primary"
              @click="submitInfos"
            />
          </div>

          <div v-if="showProgress">
            <label>Envio do Arquivo: {{ uploadPercent }}%</label>
            <z-linear-progress
              color="primary"
              :value="uploadPercent"
              height="15"
              style="background: lightgray; border-radius: 5px"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import S3Service from "@/services/S3Service";
import ApiClient from "@/services/ApiClient";
import axios from "axios";

export default {
  data() {
    return {
      computedFile: { file: null, clea_table_data: false, selected_file: "" },
      s3Url: "",
      S3Service: new S3Service(),
      http: new ApiClient(),
      uploadPercent: 0,
      showProgress: false,
      deleteData: false,
    };
  },
  computed: {
    hasDocument() {
      return this.computedFile.selected_file.name;
    },
    documentLabel() {
      let msg = "Escolha de documento";

      if (this.computedFile.selected_file.name) msg = "Documento selecionado";

      return msg;
    },
  },
  methods: {
    async openFilePicker() {
      this.$refs.file.click();
    },

    async previewFiles(event) {
      this.computedFile.selected_file = event.target.files[0];

      const file_size_in_mb =
        this.computedFile.selected_file.size / (1024 * 1024);

      if (file_size_in_mb > 100)
        this.$toas.warning("O tamanho do arquivo deve ser menor que 100mb!");
      else {
        let reader = new FileReader();
        reader.readAsDataURL(event.target.files[0]);

        reader.onload = async () => {
          const data = await reader.result;
          this.computedFile.file = data;
        };
      }
    },

    async submitInfos() {
      console.log(this.computedFile, "Information to be submit");

      await this.sendFile();

      // console.log(this.s3Url);
    },

    async sendFile() {
      try {
        const signedUrl = await this.S3Service.getSignedUrlOut(
          "putObject",
          this.computedFile.selected_file.type,
          this.computedFile.selected_file.name
        );

        console.log(signedUrl);

        this.s3Url = signedUrl.key;

        this.showProgress = true;
        await axios
          .put(signedUrl.url, this.computedFile.selected_file, {
            headers: {
              "Content-Type": this.computedFile.selected_file.type,
            },
            onUploadProgress: (e) => {
              this.uploadPercent = Math.round((e.loaded * 100) / e.total);
            },
          })
          .then((obj) => {
            console.log(obj);
            this.sendInfos();
          })
          .catch(() => {
            this.$toast.error("Erro ao salvar arquivo CSV!");
          });

        console.log(this.s3Url);
      } catch (e) {
        console.log(e);
        this.$toast.error("Erro ao salvar arquivo CSV!");
      }
      this.canGoFoward = true;
    },

    async sendInfos() {
      let configType = this.$route.query.type;

      let postObject = {
        configId: this.$route.query.id,
        payload: {
          deleteData: this.deleteData,
          fileName: this.s3Url,
        },
      };

      let resp = null;

      try {
        if (configType === "additionalData") {
          resp = await this.http.post(
            "additionalDataConfiguration/addDataByCsv",
            postObject
          );
        } else {
          resp = await this.http.post(
            "dbConfiguration/addDataByCsv",
            postObject
          );
        }
        this.$toast.success("Arquivo CSV salvo com sucesso!");

        if (configType === "additionalData") {
          this.$router.push({ path: "/additional-data" });
        } else {
          this.$router.push({ path: "/primary-data" });
        }
      } catch (e) {
        this.$toast.error("Erro ao enviar arquivo!");
      }

      console.log(resp);
    },

    resetInfos() {
      if (this.hasDocument) {
        this.computedFile.file = null;
        this.computedFile.clea_table_data = false;
        this.computedFile.selected_file = "";
        this.deleteData = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/main.scss";

.wrapper {
  color: $z-black-1;
  padding: $z-s-1;
  height: calc(100vh - 48px);
  &__card {
    padding: $z-s-1;
    background: #ffffff;
    border-radius: 15px;
    height: 100%;
    overflow-y: hidden;
    &__header {
      display: flex;
      align-items: center;
      gap: $z-s-1;
      margin-bottom: $z-s-2;
      flex-wrap: wrap;
      .left {
        flex: 1 1 auto;
        span {
          font-weight: 700;
          font-size: 1.1em;
          line-height: 15px;
        }
      }
    }
    &__body {
      .content {
        max-width: 50%;

        display: flex;
        flex-direction: column;
        gap: 2rem;

        &__body {
          display: flex;
          flex-direction: column;
          gap: 1.5rem;

          .actions-middle {
            display: flex;
            align-items: center;
            gap: 0.5rem;
            flex-wrap: wrap;
            //   button {
            //      flex: 1 1 200px;
            // }
          }
        }

        @media (max-width: 850px) {
          max-width: 100%;
        }
      }
    }
  }

  @media (max-width: 850px) {
    // height: auto;
  }
}
</style>
